<template>
    <div class="goods-container">
        <div class="goods">
            <img class="img" :src="img_url"/>
            <div class="content">
                <div class="description text-ellipsis2">{{ description }}</div>
                <div class="unit_price">
                    <span> {{specText ? specText : '默认规格'}} </span>
                    <span> ￥{{ unit_price }}/件 </span>
                    <span> 共{{ num }}件 </span>
                </div>
                <div class="total_price">
                    {{ total_price }}
                    <div class="goods-status-text" v-if="!verificationStatus && goodsStatusText ">
                        {{goodsStatusText}}
                    </div>
                </div>

                <div class="slot">
                    <slot></slot>
                </div>
            </div>
            <a v-if="verificationStatus" class="verification_text">已核销</a>
            <div class="refund-info">
                <template v-if="refundLog.status == 0">客户申请退款</template>
                <template v-if="refundLog.status == 1">已退款</template>
                <template v-if="refundLog.status == 2">拒绝退款</template>
            </div>
        </div>
        <div class="refund-info-container" v-if="showRefund">
            退款原因：{{refundLog.reason}} {{refundLog.content}}
        </div>
    </div>
</template>

<script>
  export default {
    name: 'goodsV2',
    props: {
      img_url: {
        type: String,
        default: '',
      },
      description: {
        type: String,
        default: '',
      },
      num: {
        type: [String, Number],
        default: 0,
      },
      unit_price: {
        type: [String, Number],
        default: '',
      },
      total_price: {
        type: [String, Number],
        default: '',
      },
      params: null,
      verificationStatus: {
        type: Boolean,
        default: false,
      },
      verificationText: {
        type: Boolean,
        default: false,
      },
      showRefund: {
        type: Boolean,
        default: false,
      },
      refundLog: {
        type: Object,
        default: () => {
          return {}
        }
      },
      goodsStatusText: {
        type: String,
        default: ''
      },
      specText:{
        type:String,
        default: '默认规格'
      }
    },
  }
</script>

<style lang="scss" scoped>
    .goods-container {
        display: flex;
        flex-direction: column;
    }

    @include b(goods) {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        margin-bottom: 20px;
        position: relative;
    }

    .refund-info-container {
        flex: 1;
        margin-bottom: 20px;
        font-size: 14px;
        color: #108ee9;
    }

    @include b(img) {
        flex-shrink: 0;
        width: 80px;
        height: 80px;
        border-radius: 4px;
    }

    @include b(content) {
        position: relative;
        flex-grow: 1;
        box-sizing: border-box;
        padding-left: 13px;
        display: flex;
        flex-wrap: wrap;
    }

    @include b(description) {
        width: 100%;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
    }

    @include b(unit_price) {
        width: 100%;
        margin-top: 9px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #939393;
        & > span:last-of-type {
            margin-left: 5px;
        }
    }

    @include b(total_price) {
        width: 100%;
        margin-top: 9px;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #c10000;
        display: flex;
        align-items: center;
    }

    @include b(slot) {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    /deep/ .nut-checkbox input:checked {
        background-color: #f46a17;
    }

    .verification_text {
        font-size: 12px;
        color: #f46a17;
        position: absolute;
        right: 0;
        top: 0;
        fotn-weight: bold;
    }

    .refund-info {
        font-size: 14px;
        color: #ff0000;
        position: absolute;
        right: 0;
        bottom: 0;
        fotn-weight: bold;
        width: 50%;
        display: flex;
        justify-content: flex-end;
    }

    .goods-status-text {
        color: #108ee9;
        font-size: 14px;
        margin-left: 10px;
        font-weight: bold;
    }
</style>
